import * as Sentry from "@sentry/nextjs";
import { AppError } from "./AppError";

export const SentryErrorBoundary = ({ children, ...props }: Sentry.ErrorBoundaryProps): JSX.Element => {
  return (
    // Purposefully not using the showDialog as we don't get notified of the form POSTs and
    // so it gives a worse impression.
    <Sentry.ErrorBoundary fallback={<AppError />} {...props}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
