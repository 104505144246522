import { classNames } from "@/lib/utils";
import { EntityIcon, EntityIconType } from "@components/library/Entities/EntityIcon";
import { ArrowUDownLeft } from "@phosphor-icons/react";

interface Props {
  entityType: EntityIconType;
  title: string;
  path: string;
}

export const SearchItem = ({ entityType, path, title }: Props) => {
  const directories = path.split("/");
  // Show the first and last directory
  const directoriesString =
    directories.length > 2 ? `${directories[0]} / ... / ${directories[directories.length - 1]}` : directories.join("/");

  return (
    <div className="flex h-40 items-center gap-8 px-4">
      <div
        className={classNames(
          "flex h-full w-full grow items-center gap-8 rounded-ms px-12 outline outline-transparent",
          // "group-hover/searchitem:bg-background-base-3 group-hover/searchitem:outline-stroke-base-2",
          // "hover" state not used as items go into selected state on hover.
          // "active:border active:border-stroke-secondary-1 active:bg-background-secondary-1",
          // This "selected" state seems to only be used on the initial render, where the first item is "selected" but not "focused"?
          // Seems unimportant.
          // Only focus state is used.
          "group-data-[selected=true]/searchitem:bg-background-secondary-1 group-data-[selected=true]/searchitem:outline-stroke-secondary-1",
        )}
      >
        <EntityIcon type={entityType} size={16} />
        <div className="shrink-0 text-12-12 font-medium text-text-base-1 group-data-[selected=true]/searchitem:text-text-secondary-1">
          {title}
        </div>
        <span className="-ml-2 min-w-0 shrink grow truncate text-12-12 text-text-base-4"> — {directoriesString}</span>
        <ArrowUDownLeft
          size={14}
          className="invisible flex-none text-icon-secondary-1 group-data-[selected=true]/searchitem:visible"
        />
      </div>
    </div>
  );
};
