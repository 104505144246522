import { joinElements } from "@/lib/react-utils";
import { classNames } from "@/lib/utils";
import { Directory, DirectoryWithParentsAndChildren } from "@/services/directories.service";
import { File } from "@/types/app/file";
import { Breadcrumb, BreadcrumbSeparator, HomeBreadcrumb } from "@components/library/Breadcrumbs";
import _ from "lodash";
import { useRouter } from "next/router";
import { ComponentProps } from "react";

interface Props {
  directory: DirectoryWithParentsAndChildren;

  /**
   * The file to display as the last breadcrumb.
   *
   * This is optional, and if not provided, the last breadcrumb will be the current directory.
   */
  file?: File;

  onClick?: (directory: Directory) => void;
  links?: boolean;
  className?: string;

  /**
   * Whether to render a breadcrumb path even for the root directory.
   *
   * Since it has no parents, this is simply the Lissajouse logo.
   *
   * Default: false
   */
  renderRoot?: boolean;

  /**
   * Classes to pass to the Breadcrumb component.
   */
  breadcrumbStyle?: ComponentProps<typeof Breadcrumb>["breadcrumbStyle"];

  /**
   * Whether the breadcrumbs are disabled, i.e. the user cannot click on them.
   */
  disabled?: boolean;
}

export const DirectoryBreadcrumbs = ({
  directory,
  file,
  onClick,
  links,
  renderRoot = false,
  className,
  breadcrumbStyle,
  disabled,
}: Props) => {
  const router = useRouter();
  if (directory.parents.length === 0 && !renderRoot) {
    console.log(`Cannot render breadcrumbs for directory ${directory.id} because it has no parents.`);
    return null;
  }

  const root = directory.parents.length === 0 ? directory : directory.parents[0];

  const breadcrumbs = [...directory.parents, directory].map((dir, i) => {
    // First breadcrumb is the root directory with name [HOME_DIRECTORY_NAME].
    const rootDirectory = i === 0;

    if (rootDirectory && directory.parents.length > 0) {
      // If we are rendering the root directory and the current directory is not
      // itself the root directory, then we don't show the "Home" breadcrumb.
      // The "Home" breadcrumb is displayed after the Lissajous only when there
      // is no further breadcrumb path, in order to make it look less empty.
      return null;
    }

    const directoryName = dir.name;
    if (links) {
      return (
        <Breadcrumb
          key={i}
          size={32}
          href={
            rootDirectory
              ? { pathname: router.pathname, query: _.omit(router.query, "id") }
              : { pathname: router.pathname, query: { ...router.query, id: dir.id } }
          }
          {...{
            [DATA_DIRECTORY_ID]: dir.id,
            [DATA_DIRECTORY_NAME]: directoryName,
          }}
          breadcrumbStyle={breadcrumbStyle}
          disabled={disabled}
        >
          {directoryName}
        </Breadcrumb>
      );
    } else {
      return (
        <Breadcrumb
          key={i}
          size={32}
          onClick={onClick ? () => onClick(dir) : undefined}
          {...{
            [DATA_DIRECTORY_ID]: dir.id,
            [DATA_DIRECTORY_NAME]: directoryName,
          }}
          breadcrumbStyle={breadcrumbStyle}
          disabled={disabled}
        >
          {directoryName}
        </Breadcrumb>
      );
    }
  });

  const fileBreadcrumb = file ? (
    links ? (
      <Breadcrumb
        size={32}
        href={{
          pathname: router.pathname,
          query: { ...router.query, id: file.id },
        }}
        breadcrumbStyle={breadcrumbStyle}
        disabled={disabled}
      >
        {file.name}
      </Breadcrumb>
    ) : (
      <Breadcrumb
        size={32}
        onClick={onClick ? () => onClick(root) : undefined}
        breadcrumbStyle={breadcrumbStyle}
        disabled={disabled}
      >
        {file.name}
      </Breadcrumb>
    )
  ) : null;

  return (
    <div className={classNames("flex items-center pl-4", className)}>
      <HomeBreadcrumb
        size={32}
        links={false}
        breadcrumbStyle={breadcrumbStyle}
        onClick={() => onClick && onClick(root)}
        showName={breadcrumbs.length === 1}
        disabled={disabled}
      />
      {breadcrumbs.length > 1 ? (
        <>
          <BreadcrumbSeparator size={32} />
          {joinElements(breadcrumbs.filter(Boolean), <BreadcrumbSeparator size={32} />)}
        </>
      ) : null}
      {fileBreadcrumb && (
        <>
          <BreadcrumbSeparator size={32} />
          {fileBreadcrumb}
        </>
      )}
    </div>
  );
};

export const DATA_DIRECTORY_ID = "data-directory-id";
export const DATA_DIRECTORY_NAME = "data-directory-name";
