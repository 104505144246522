import { parseTimestampsInResponse } from "@/services/utils";
import { BaseFileResponse, FileFromResponse } from "./file";
import { FileRequestIdentifiers } from "./log";
import { parseEnvironmentResponse } from "./environment";
import {
  MonitoringEvaluator,
  MonitoringEvaluatorResponse,
  parseMonitoringEvaluatorResponse,
} from "@/services/files.service";

export interface FlowKernelRequest {
  attributes: object;
}

export interface FlowRequest extends FlowKernelRequest, FileRequestIdentifiers {
  commit_message?: string | null;
}

export interface FlowResponse extends Omit<FlowRequest, "id" | "path" | "directory_id">, BaseFileResponse<"flow"> {
  version_logs_count: number;
  total_logs_count: number;
  evaluators: MonitoringEvaluatorResponse[] | null;
}

export interface Flow extends Omit<FileFromResponse<FlowResponse>, "evaluators"> {
  evaluators: MonitoringEvaluator[] | null;
}

export const parseFlowResponse = (response: FlowResponse): Flow => {
  return {
    ...parseTimestampsInResponse(response, ["created_at", "updated_at", "committed_at", "last_used_at"]),
    environments: response.environments.map(parseEnvironmentResponse),
    evaluators: response.evaluators
      ? response.evaluators.map((evaluator) => parseMonitoringEvaluatorResponse(evaluator))
      : null,
  };
};
