import { useEffect } from "react";
import usePrevious from "./use-previous";

const removePointerEventsNoneFromBody = (): void => {
  const bodyElement = document.querySelector("body");
  if (bodyElement && bodyElement.style.pointerEvents === "none") {
    bodyElement.style.removeProperty("pointer-events");
  }
};

/**
 * Remove pointer-events: none left behind by radix
 *
 * See: https://github.com/radix-ui/primitives/issues/2416
 * Note that this does not happen all radix dialog usages in the app, so it's unclear what causes this.
 */
export const usePointerEventsCleanup = (open: boolean) => {
  // Remove pointer-events: none left behind by radix
  // See: https://github.com/radix-ui/primitives/issues/2416
  const previousOpen = usePrevious(open);
  useEffect(() => {
    if (previousOpen && !open) {
      removePointerEventsNoneFromBody();
      setTimeout(removePointerEventsNoneFromBody, 100);
    }
  }, [open, previousOpen]);
  // And on unmount
  useEffect(() => {
    removePointerEventsNoneFromBody();
  }, []);
};
