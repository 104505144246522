// Modal and Dialog components

// A modal is a window that appears on top of the page and requires interactions to dismiss
// A dialog is a modal that requires user input

import { usePointerEventsCleanup } from "@/lib/use-pointer-events-cleanup";
import usePrevious from "@/lib/use-previous";
import { SentryErrorBoundary } from "@components/error/SentryErrorBoundary";
import IconButton from "@components/library/IconButton";
import { Dialog } from "@headlessui/react";
import { ChevronRightIcon, XIcon } from "@heroicons/react/outline";
import * as RadixDialog from "@radix-ui/react-dialog";
import { classNames } from "lib/utils";
import React, { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export interface ModalProps {
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  dialogProps?: React.ComponentPropsWithoutRef<typeof Dialog>;
  className?: string;
  width?: "sm" | "normal" | "medium" | "wide" | "xl";
}

const widthClassMap = {
  sm: "w-[420px]", // FYI designs are 384px. This might be better in practice, needs audit.
  normal: "w-[480px]", // default
  medium: "w-[600px]",
  wide: "w-[736px]",
  xl: "w-[928px]",
};

// Base modal without forcing any structure on children
export const Modal = ({ open, onClose, children, dialogProps, className, width = "normal" }: ModalProps) => {
  const widthClass = widthClassMap[width];

  return (
    // Antd drawer is z-[1000]
    <Dialog
      open={open}
      onClose={onClose || (() => {})}
      className="fixed inset-0 z-[1001] overflow-y-auto"
      {...dialogProps}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        <div
          className={classNames(
            "relative mx-auto min-h-[72px] overflow-clip rounded-lg border border-oldgray-400 bg-white shadow-c",
            widthClass,
            className,
          )}
        >
          {onClose && (
            <div className="absolute right-16 top-16 z-[1000]">
              <IconButton tabIndex={-1} Icon={XIcon} size={32} onClick={onClose} shade={"gray"} />
            </div>
          )}
          {children}
        </div>
      </div>
    </Dialog>
  );
};

interface DialogProps extends ModalProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

const AppDialog = ({ open, onClose, title, description, children, dialogProps, className, width }: DialogProps) => {
  return (
    <Modal open={open} onClose={onClose} dialogProps={dialogProps} className={classNames(className)} width={width}>
      <div className="flex flex-col gap-y-12 p-32">
        {title && <Dialog.Title className="flex items-center text-18-24 font-bold">{title}</Dialog.Title>}
        {description && <Dialog.Description className="text-14-20 text-text-base-3">{description}</Dialog.Description>}
      </div>
      <div className="flex grow flex-col gap-y-12 px-32 pb-32">{children}</div>
    </Modal>
  );
};

export default AppDialog;

export const RadixAppDialog = ({
  open,
  onClose,
  title,
  description,
  children,
  dialogProps,
  className,
  width = "normal",
}: DialogProps) => {
  usePointerEventsCleanup(open);

  const widthClass = widthClassMap[width];

  return (
    <RadixDialog.Root open={open} onOpenChange={(open) => onClose?.()} {...dialogProps}>
      <RadixDialog.Portal>
        {/* Antd drawer is z-[1000] */}
        <div
          className="fixed inset-0 z-[1001] overflow-y-auto"
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              e.stopPropagation();
            }
          }}
        >
          <div className="flex min-h-screen items-center justify-center">
            <RadixDialog.Overlay className="fixed inset-0 bg-black opacity-20" />

            <RadixDialog.Content
              onEscapeKeyDown={onClose}
              className={classNames(
                "relative mx-auto flex min-h-[72px] flex-col rounded-lg border border-oldgray-400 bg-white shadow-modal",
                className,
                widthClass,
              )}
              onPointerDownOutside={(event) => {
                // If click is in headlessui dialog (e.g. through useConfirm), don't close
                // Do so by checking if child of element with id="headlessui-portal-root"
                const target = event.target as HTMLElement | null;
                if (target?.closest("#headlessui-portal-root")) {
                  event.preventDefault();
                  return;
                }
              }}
            >
              {onClose && (
                <div className="absolute right-16 top-16">
                  <IconButton onClick={onClose} Icon={XIcon} size={32} shade={"gray"} className="z-[1000]" />
                </div>
              )}
              <div className="flex flex-col gap-y-12 px-32 pb-24 pt-32">
                {title && (
                  <RadixDialog.Title className="flex items-center text-18-24 font-bold">{title}</RadixDialog.Title>
                )}
                {description && (
                  <RadixDialog.Description className="text-13-18 text-oldgray-700">
                    {description}
                  </RadixDialog.Description>
                )}
              </div>
              <div className="flex min-h-0 grow flex-col gap-y-12 px-32 pb-32">
                <SentryErrorBoundary>{children}</SentryErrorBoundary>
              </div>
            </RadixDialog.Content>
          </div>
        </div>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export const DialogSection = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className="flex flex-col gap-8">{children}</div>;
};

export const DialogLabel = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className="text-12-12 font-bold text-oldgray-700">{children}</div>;
};

export const DialogItems = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className="-mx-16 flex max-h-[200px] flex-col overflow-y-auto">{children}</div>;
};

interface DialogItemProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
}

export const DialogItem = ({ onClick, children, disabled }: React.PropsWithChildren<DialogItemProps>) => {
  return (
    <div
      className={
        disabled
          ? classNames(
              "group flex h-36 shrink-0 items-center justify-between rounded-sm border-b-2 bg-oldgray-100 pl-16 pr-[20px] text-14-16 text-oldgray-700 last:border-b-0 ",
            )
          : classNames(
              "group flex h-36 shrink-0 items-center justify-between px-16 text-14-16 text-text-base-2",
              onClick && "cursor-pointer rounded-sm hover:bg-background-base-2 hover:text-text-base-1",
            )
      }
      onClick={disabled ? () => {} : onClick}
    >
      {children}

      <div className="flex items-center gap-4 ">
        {onClick && !disabled && <ChevronRightIcon className="h-12 w-12" />}
      </div>
    </div>
  );
};
