import { BaseFileResponse, FileFromResponse } from "./file";
import { DashboardConfiguration } from "@/services/projects.service";
import {
  MonitoringEvaluator,
  MonitoringEvaluatorResponse,
  parseMonitoringEvaluatorResponse,
} from "@/services/files.service";
import { parseTimestampsInResponse } from "@/services/utils";
import { parseEnvironmentResponse } from "./environment";
import { EvaluatorAggregate } from "./version";
import { FileRequestIdentifiers } from "./log";

export interface ToolFunction {
  name: string;
  description: string;
  parameters?: Record<string, any> | null;
}

export interface ToolKernelRequest {
  function?: ToolFunction | null;
  source_code?: string | null;
  setup_values?: Record<string, any> | null;
  attributes?: Record<string, any> | null;
}

export interface ToolRequest extends ToolKernelRequest, FileRequestIdentifiers {
  tool_type: ToolType;
  commit_message?: string | null;
}

export interface UpdateToolRequest {
  path?: string | null;
  name?: string | null;
}

export interface ToolResponse extends Omit<ToolRequest, "id" | "path" | "directory_id">, BaseFileResponse<"tool"> {
  signature: string | null;

  version_logs_count: number;
  total_logs_count: number;

  inputs: { name: string }[];
  team_id: string;
  dashboard_configuration?: DashboardConfiguration;
  evaluators: MonitoringEvaluatorResponse[] | null;
  setup_schema: Record<string, any> | null;
  evaluator_aggregates: EvaluatorAggregate[] | null;
}

export interface Tool extends Omit<FileFromResponse<ToolResponse>, "evaluators"> {
  evaluators: MonitoringEvaluator[] | null;
}

export const parseToolResponse = (response: ToolResponse): Tool => {
  return {
    ...parseTimestampsInResponse(response, ["created_at", "updated_at", "committed_at", "last_used_at"]),
    environments: response.environments.map(parseEnvironmentResponse),
    evaluators: response.evaluators
      ? response.evaluators.map((evaluator) => parseMonitoringEvaluatorResponse(evaluator))
      : null,
  };
};

export interface ToolTemplateResponse extends ToolFunction {
  name: ToolType;
  signature: string | null;
  setup_schema: Record<string, any> | null;
}

export type ToolType = "pinecone_search" | "google" | "snippet" | "json_schema" | "get_api_call";
