import dayjs from "dayjs";

export const parseTimestamp = (timestamp: string): dayjs.Dayjs => {
  return dayjs.utc(timestamp).local();
};

export type ReplaceKeysInType<Original, Keys extends keyof Original, NewType> = {
  [K in keyof Original]: K extends Keys ? NewType : Original[K];
};

export const parseTimestampsInResponse = <Response extends Record<string, any>, K extends keyof Response>(
  response: Response,
  keys: K[],
): ReplaceKeysInType<Response, K, dayjs.Dayjs> => {
  let parsedData: Partial<Response> = { ...response };
  keys.forEach((key) => {
    if (parsedData[key]) {
      parsedData[key] = parseTimestamp(response[key] as string) as any;
    }
  });
  return parsedData as ReplaceKeysInType<Response, K, dayjs.Dayjs>;
};
