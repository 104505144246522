import { CheckCircleIcon, ExclamationCircleIcon, ExclamationIcon } from "@heroicons/react/outline";
import { classNames } from "lib/utils";
import React from "react";
import { InsightIcon, LoadingAltIcon } from "./Icons";

export const enum AlertVariant {
  Loading = "Loading",
  Info = "Info",
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
}

interface Props {
  title: React.ReactNode;
  description: React.ReactNode;
  button?: React.ReactNode;
  variant: AlertVariant;
  size?: "sm" | "md";
}

const Alert = ({ title, description, button, variant, size = "md" }: Props): JSX.Element => {
  const classes = {
    base: classNames("flex items-center rounded border", size === "sm" ? "py-16 px-12" : "py-32 px-24"),
    icon: classNames(" flex-shrink-0", size === "sm" ? "w-20 h-20 mr-12" : "w-32 h-32 mr-24"),
    title: classNames("font-bold text-black", size === "sm" ? "text-14-20" : "text-18-24 "),
    description: classNames("text-black mt-6 max-w-prose", size === "sm" ? "text-13-18" : "text-16-20"),
    variants: {
      [AlertVariant.Loading]: { classes: "bg-oldgray-200 border-oldgray-300 text-oldgray-600", icon: LoadingAltIcon },
      [AlertVariant.Info]: { classes: "bg-oldblue-100 border-oldblue-400 text-oldblue-600", icon: InsightIcon },
      [AlertVariant.Success]: {
        classes: "bg-oldgreen-100 border-oldgreen-200 text-oldgreen-600",
        icon: CheckCircleIcon,
      },
      [AlertVariant.Warning]: {
        classes: "bg-oldyellow-100/50 border-oldyellow-200 text-oldyellow-600",
        icon: ExclamationIcon,
      },
      [AlertVariant.Error]: {
        classes: "bg-oldred-100/30 border-oldred-300 text-oldred-600",
        icon: ExclamationCircleIcon,
      },
    },
  };
  const Icon = classes.variants[variant].icon;
  return (
    <div className={classNames(classes.base, classes.variants[variant].classes)}>
      <div className={classes.icon}>
        <Icon />
      </div>
      <div className="flex flex-grow flex-col">
        <span className={classes.title}>{title}</span>
        <span className={classes.description}>{description}</span>
      </div>
      {button && <div>{button}</div>}
    </div>
  );
};

export default Alert;
