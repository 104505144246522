import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { classNames } from "@/lib/utils";
import { LissajousLogo } from "@components/library/LissajousLogo";
import { CaretRight } from "@phosphor-icons/react";
import Link, { LinkProps } from "next/link";
import styles from "./Breadcrumbs.module.css";
import { ElementType } from "react";
import { UnstyledButtonBase, UnstyledButtonBaseProps } from "./Button";
import { CircleIcon } from "./Icons";

type BreadcrumbSize = 24 | 32;

export const BreadcrumbSeparator = ({ size }: { size: BreadcrumbSize }) => {
  const { className, iconSize } = BREADCRUMB_SEPARATOR_STYLES[size];
  return (
    <div className={classNames("flex shrink-0 items-center justify-center", className)}>
      <CaretRight size={iconSize} className="text-stroke-base-6" />
    </div>
  );
};

const BREADCRUMB_SEPARATOR_STYLES: Record<BreadcrumbSize, { className: string; iconSize: number }> = {
  24: { className: "h-24 w-12", iconSize: 8 },
  32: { className: "h-32 w-14", iconSize: 10 },
};

const BREADCRUMB_SIZES: Record<BreadcrumbSize, string> = {
  24: "p-6 h-24 text-12-14",
  32: "p-8 h-32 text-13-14",
};

const BREADCRUMB_STYLES = {
  light: "font-light",
  normal: "font-normal",
  medium: "font-medium",
};
export interface BreadcrumbProps extends UnstyledButtonBaseProps {
  size: BreadcrumbSize;
  breadcrumbStyle?: keyof typeof BREADCRUMB_STYLES;
  disabled?: boolean;
}

export const Breadcrumb = ({
  size,
  breadcrumbStyle = "medium",
  children,
  disabled,
  className,
  ...props
}: BreadcrumbProps) => {
  const classes = classNames(
    styles.breadcrumb,
    "flex max-w-[26ch] items-center truncate rounded-ms font-medium text-text-base-3",
    disabled
      ? "cursor-not-allowed"
      : "cursor-pointer hover:bg-background-base-325 hover:text-text-base-1 focus:bg-background-base-1 focus:shadow-focus-ring focus-visible:shadow-focus-ring focus-visible:outline-none",
    BREADCRUMB_SIZES[size],
    BREADCRUMB_STYLES[breadcrumbStyle],
    className,
  );

  return (
    <UnstyledButtonBase {...props} className={classes} title={typeof children === "string" ? children : undefined}>
      {typeof children === "string" ? <span className="truncate">{children}</span> : children}
    </UnstyledButtonBase>
  );
};

export const HomeBreadcrumb = ({
  size,
  breadcrumbStyle,
  onClick,
  links = true,
  disabled,
  showName = false,
}: {
  size: BreadcrumbSize;
  breadcrumbStyle?: keyof typeof BREADCRUMB_STYLES;
  className?: string;
  onClick?: () => void;
  links?: boolean;
  disabled?: boolean;
  showName?: boolean;
}) => {
  const { slug } = useActiveOrganization();
  const className = size === 24 ? "h-14 w-14" : "h-16 w-16";
  return (
    <Breadcrumb
      {...(links ? { href: `/${slug}` } : { onClick })}
      size={size}
      breadcrumbStyle={breadcrumbStyle}
      disabled={disabled}
    >
      <div className="">
        <LissajousLogo className={className} />
      </div>
      {showName && <span className="ml-6">Home</span>}
    </Breadcrumb>
  );
};
