import {
  Directory,
  DirectoryWithParentsAndChildren,
  FileOrDirectory,
  moveItemOperations,
  useDirectories,
  useDirectory,
} from "@/services/directories.service";
import { RadixAppDialog } from "@components/library/AppDialog";
import { DirectoryBreadcrumbs } from "./DirectoriesBreadcrumbs";
import { useCallback, useEffect, useState } from "react";
import RadioButton from "@components/library/RadioButton";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { ConditionalWrapper, classNames, pluralise } from "@/lib/utils";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import Button from "@components/library/Button";
import { ToastVariant, hlToast, hlToastApiError } from "@components/library/Toast";
import Tooltip from "@components/library/Tooltip";
import { getDirectoryHref } from "@/lib/path-utils";
import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import useConfirm, { ConfirmType } from "@/context/useConfirm";
import { shouldWarnOnPathChange } from "@/services/files.service";
import Alert, { AlertVariant } from "@components/library/Alert";
import { ROOT_DIRECTORY_NAME } from "@/lib/constants";

interface OwnProps {
  items: FileOrDirectory[] | null;
  onMove: (
    move?: { items: FileOrDirectory[]; targetDirectoryId: string }, // Undefined if the move failed (and might have partially succeeded)
  ) => void;
}

type Props = OwnProps & Pick<React.ComponentPropsWithoutRef<typeof RadixAppDialog>, "open" | "onClose">;

/** Move a file or directory into a different directory */
export const MoveDirectoryModal = ({ open, onClose, onMove, items }: Props) => {
  const initialDirectoryId = items && items.length > 0 ? getParentDirectoryId(items[0]) : null;
  const [directoryId, setDirectoryId] = useState<string | null>(initialDirectoryId);

  const confirm = useConfirm();

  // Update the directoryId if the initialDirectoryId changes
  useEffect(() => {
    if (initialDirectoryId) {
      setDirectoryId(initialDirectoryId);
    }
  }, [initialDirectoryId]);

  const { directory } = useDirectory(directoryId, { keepPreviousData: true });
  const { directories } = useDirectories();

  const [selectedDirectory, setSelectedDirectory] = useState<Directory | null>(null);

  const onNavigate = useCallback(
    (directory: Directory) => {
      setDirectoryId(directory.id);
      setSelectedDirectory(null);
    },
    [setDirectoryId, setSelectedDirectory],
  );

  const moveHere = selectedDirectory === null && directory?.parent_id === null;
  const targetDirectory = moveHere ? directory : selectedDirectory;
  const targetDirectoryId = targetDirectory?.id || null;
  const targetDirectoryName = targetDirectory?.name || null;
  const { slug } = useActiveOrganization();

  const handleMove = useCallback(async () => {
    if (targetDirectoryId === null) {
      throw new Error("No target directory selected.");
    }
    if (items === null || items.length === 0) {
      return;
    }

    try {
      // Closing the dialog before the confirmation dialog to prevent double dialogs open at the same time
      onClose?.();

      // Check if the user confirmed the move when warning was shown
      if (!(await confirmMovePathWarning(items, targetDirectoryName, confirm))) {
        hlToast({ title: "Move cancelled", variant: ToastVariant.Info });
        return;
      }

      const moveOperations = moveItemOperations(items, targetDirectoryId);
      await Promise.all(moveOperations.map((op) => op.forward()));
      hlToast({
        title: `Moved ${items.length} item${pluralise(items.length)} to ${
          targetDirectoryName === ROOT_DIRECTORY_NAME ? "root directory" : `'${targetDirectoryName}'`
        }`,
        description: (
          <Button size={24} href={getDirectoryHref({ slug, id: targetDirectoryId })}>
            Go to directory
          </Button>
        ),
        variant: ToastVariant.Success,
      });
      onMove({ items, targetDirectoryId });
    } catch (e) {
      console.error(e);
      if (items.length === 1) {
        hlToastApiError({ error: e, titleFallback: `Failed to move '${items[0].item.name}'` });
      } else {
        hlToast({
          title: `Failed to move ${items.length} item${pluralise(items.length)}`,
          description: "Some items may have been moved.",
          variant: ToastVariant.Error,
        });
      }
      onMove(); // Call this just in case some of the items were moved successfully.
    }
  }, [items, onClose, onMove, slug, targetDirectoryId, targetDirectoryName, confirm]);

  if (items === null || items.length === 0) return null;

  const targetDirectoryValidity =
    targetDirectory !== null && directories !== undefined
      ? // TODO: Consider not checking validity for all items as we only use the first invalid item now.
        items.map((item) => ({
          ...getTargetDirectoryIsValid({
            targetDirectory,
            item,
            directories,
          }),
          item,
        }))
      : null;

  const invalidItem =
    (targetDirectoryValidity?.find(({ valid }) => !valid) as  // Type assertion needed here as TypeScript doesn't seem to infer that the 'valid' property is false.
      | {
          item: FileOrDirectory;
          valid: false;
          reason: string;
        }
      | undefined) || null;

  const targetDirectoryIsValid =
    targetDirectoryValidity !== null ? targetDirectoryValidity.every(({ valid }) => valid) : true;

  const targetDirectoryValidityMessage: string | null = invalidItem
    ? `Invalid target directory for '${invalidItem.item.item.name}'. ${invalidItem.reason}`
    : targetDirectoryId === initialDirectoryId
      ? `Already in this directory`
      : null;

  return (
    <RadixAppDialog
      title={
        <div className="truncate">
          {items.length === 1 ? `Move '${items[0].item.name}'` : `Move ${items.length} items`}
        </div>
      }
      open={open}
      onClose={onClose}
      className="h-[466px]"
    >
      <div className="flex min-h-0 grow flex-col">
        <div className="-mx-32 flex min-h-[48px] shrink-0 items-center border-b px-24">
          {directory && (
            <DirectoryBreadcrumbs directory={directory} onClick={onNavigate} className="min-w-0 flex-wrap" />
          )}
        </div>

        <div className="-mx-32 min-h-0 grow">
          <ScrollArea.Root className="h-full">
            <ScrollArea.Viewport className="h-full [&>div]:!block">
              <div className="px-16 py-16 pb-32">
                {directory && (
                  <DirectoriesList
                    directory={directory}
                    selectedDirectory={selectedDirectory}
                    setSelectedDirectory={setSelectedDirectory}
                    onNavigate={onNavigate}
                  />
                )}
              </div>
            </ScrollArea.Viewport>

            <ScrollArea.Scrollbar
              orientation="vertical"
              className="z-50 flex w-[7px] overflow-hidden rounded bg-gray-100 p-2"
            >
              <ScrollArea.Thumb className="flex-auto rounded bg-gray-200" />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
        </div>

        <div className="relative flex items-center justify-between pt-16">
          {/* Gradient above to highlight area can be scrolled */}
          <div className="absolute -inset-x-32 -top-16 h-16 bg-gradient-to-t from-white" />

          <Button onClick={onClose}>Cancel</Button>
          <ConditionalWrapper
            condition={targetDirectoryValidityMessage !== null}
            wrapper={(children) => <Tooltip content={targetDirectoryValidityMessage}>{children}</Tooltip>}
          >
            <Button
              styling="solid"
              disabled={
                targetDirectoryId === null || targetDirectoryId === initialDirectoryId || !targetDirectoryIsValid
              }
              onClick={handleMove}
            >
              {moveHere ? "Move here" : "Move to selected"}
            </Button>
          </ConditionalWrapper>
        </div>
      </div>
    </RadixAppDialog>
  );
};

const getParentDirectoryId = (item: FileOrDirectory): string => {
  switch (item.type) {
    case "directory":
      if (item.item.parent_id === null) {
        throw new Error(`Directory ${item.item.id} has no parent.`);
      }
      return item.item.parent_id;
    case "file":
      return item.item.directory_id;
  }
};

interface DirectoriesListProps {
  directory: DirectoryWithParentsAndChildren;
  selectedDirectory: Directory | null;
  setSelectedDirectory: (directory: Directory | null) => void;
  onNavigate: (directory: Directory) => void;
}

export const DirectoriesList = ({
  directory,
  selectedDirectory,
  setSelectedDirectory,
  onNavigate,
}: DirectoriesListProps) => {
  return (
    <div className="flex flex-col gap-2">
      {directory.subdirectories.map((subdirectory) => (
        <DirectoriesListItem
          key={subdirectory.id}
          directory={subdirectory}
          selected={selectedDirectory?.id === subdirectory.id}
          onSelect={() =>
            selectedDirectory?.id === subdirectory.id ? setSelectedDirectory(null) : setSelectedDirectory(subdirectory)
          }
          onNavigate={() => {
            onNavigate(subdirectory);
          }}
        />
      ))}
    </div>
  );
};

interface DirectoriesListItemProps {
  directory: Directory;
  selected: boolean;
  onSelect: () => void;
  onNavigate: () => void;
}

const DirectoriesListItem = ({ directory, selected, onSelect, onNavigate }: DirectoriesListItemProps) => (
  <button
    className={classNames(
      "group/directory flex h-40 items-center justify-between gap-12 truncate rounded-ms px-16",
      selected ? "bg-blue-50 hover:shadow-card-cta" : "hover:shadow-card-md",
    )}
    onClick={onSelect}
    onDoubleClick={onNavigate}
  >
    <div className="flex min-w-0 items-center gap-12">
      <div>
        <RadioButton checked={selected} size={16} />
      </div>
      <div className="truncate text-13-14 font-medium text-gray-950">{directory.name}</div>
    </div>
    <div className="flex items-center">
      <ChevronRightIcon className="h-14 w-14 text-gray-950" onClick={onNavigate} />
    </div>
  </button>
);

/**
 * Checks that the target directory is valid for moving the given item to.
 *
 * Specifically, the target directory must not be a descendant of the item to be moved
 * and must not be the same as any of the items to be moved.
 */
export const getTargetDirectoryIsValid = ({
  item,
  targetDirectory,
  directories,
}: {
  item: FileOrDirectory;
  targetDirectory: Directory;
  directories: Directory[];
}): { valid: true } | { valid: false; reason: string } => {
  if (item.type === "file") {
    return { valid: true };
  }
  const itemId = item.item.id;

  // Check if the target directory is the same as the item
  if (itemId === targetDirectory.id) {
    return { valid: false, reason: "Cannot move directory into itself." };
  }

  // Check if the target directory is a descendant of the item
  let parentDirectory = directories.find((directory) => directory.id === targetDirectory.parent_id);
  while (parentDirectory) {
    const loopParentDirectory = parentDirectory;
    if (loopParentDirectory.id === itemId) {
      return { valid: false, reason: "Cannot move directory into a subdirectory of itself." };
    }
    parentDirectory = directories.find((directory) => directory.id === loopParentDirectory.parent_id);
  }

  return { valid: true };
};

/**
 * Confirms with the user (if applicable) that moving will change the paths of the items.
 *
 * Only applicable if shouldWarnOnPathChange.
 */
export const confirmMovePathWarning = async (
  items: FileOrDirectory[],
  targetDirectoryName: string | null,
  confirm: ConfirmType,
): Promise<boolean> => {
  if (items.some((item) => shouldWarnOnPathChange(item))) {
    let title = `Move ${items.length} item${pluralise(items.length)}`;
    if (targetDirectoryName !== null) {
      title += ` to ${targetDirectoryName === "__ROOT__" ? "the root directory" : `'${targetDirectoryName}'`}`;
    }
    title += "?";

    try {
      await confirm({
        title,
        content: (
          <Alert
            size="sm"
            title={`Moving ${items.length === 1 ? "this item" : "these items"} can break your API calls`}
            description={`Any API calls referencing ${items.length === 1 ? "this item" : "these items"} by path (rather than ID) will need to be updated.`}
            variant={AlertVariant.Error}
          />
        ),
        confirmationText: "Move",
        cancellationText: "Cancel",
      });
      return true; // User confirmed
    } catch {
      return false; // User cancelled
    }
  }
  return true; // No warning needed
};
