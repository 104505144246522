import { ReplaceKeysInType, parseTimestampsInResponse } from "@/services/utils";
import dayjs from "dayjs";

type EnvironmentTag = "default" | "other";

export interface EnvironmentResponse {
  id: string;
  created_at: string;
  name: string;
  tag: EnvironmentTag;
}

export type Environment = ReplaceKeysInType<EnvironmentResponse, "created_at", dayjs.Dayjs>;

export const parseEnvironmentResponse = (response: EnvironmentResponse): Environment => {
  return parseTimestampsInResponse(response, ["created_at"]);
};
