import { classNames } from "lib/utils";
import React from "react";

export type RadioButtonSize = 16 | 20;

interface RadioButtonProps {
  checked: boolean;
  disabled?: boolean;
  size: RadioButtonSize;
}

const RadioButton = ({ checked, disabled, size }: RadioButtonProps) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-center rounded-full border transition-all duration-150",
        size === 16 ? "h-[16px] w-[16px]" : "h-[20px] w-[20px]",
        checked && "border-transparent",
        checked && !disabled && "bg-oldblue-600 hover:bg-oldblue-700",
        checked && disabled && "bg-oldgray-400",
        !checked && !disabled && "border-oldgray-400 hover:border-oldblue-400",
        !checked && disabled && "border-oldgray-400 bg-oldgray-300",
      )}
    >
      <div
        className={classNames(
          "rounded-full bg-white",
          size === 16 ? "h-[6px] w-[6px]" : "h-[7px] w-[7px]",
          checked ? "opacity-100" : "opacity-0",
        )}
      />
    </div>
  );
};

export default RadioButton;
