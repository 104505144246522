import { addPathsToDirectoryStructure, DirectoryFile, useDirectoryStructure } from "@/services/directories.service";
import { Command } from "cmdk";
import { SearchItem } from "./SearchItem";
import { MagnifyingGlass } from "@phosphor-icons/react";
import Link from "next/link";
import { useAppHref } from "@/lib/path-utils";
import { useRouter } from "next/router";
import { commandScore } from "./command-score";
import { useMemo } from "react";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

/** CMDK SearchDialog */
export const SearchDialog = ({ open, setOpen }: Props) => {
  const { structure: structureWithoutPaths, ...rest } = useDirectoryStructure();
  const structure = useMemo(() => {
    return structureWithoutPaths ? addPathsToDirectoryStructure(structureWithoutPaths) : structureWithoutPaths;
  }, [structureWithoutPaths]);

  return (
    <Command.Dialog
      open={open}
      onOpenChange={setOpen}
      label="Search Files"
      overlayClassName="fixed inset-0 bg-black z-10 opacity-20"
      className="fixed left-[50%] top-[10%] z-[1000] flex max-h-[80vh] w-[90vw] max-w-[512px] translate-x-[-50%] flex-col overflow-hidden rounded-lg bg-background-base-1 shadow-card-lg"
      onKeyDown={(e) => {
        // Prevent the up/down keys from also moving the active row in the table etc.
        // Prevent 'enter' from selecting any active row etc.
        if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Enter") {
          e.stopPropagation();
        }
      }}
      filter={(value, search, keywords) => {
        // Keywords are IDs - therefore we only want to exact match.
        // Check for exact keyword exact ID match
        if (keywords?.some((keyword) => keyword === search)) {
          return 1;
        }
        // Otherwise use commandScore and ignore the keywords (as they cause false positives)
        return commandScore(value, search, []);
      }}
    >
      <div className="flex h-56 shrink-0 items-center gap-8 px-16">
        <MagnifyingGlass weight="bold" size={16} className="shrink-0 text-icon-base-1" />
        <Command.Input
          autoFocus
          placeholder="Search Files..."
          className="grow border-none p-0 text-text-base-1 caret-stroke-secondary-4 placeholder:text-text-base-4 focus:border-none focus:ring-0"
        />
      </div>
      <div className="w-full border-b border-stroke-base-2" />
      <Command.List className="flex min-h-0 grow flex-col gap-2 overflow-auto py-4">
        <Command.Empty className="h-40 p-12 text-12-12 text-text-base-4">No Files found</Command.Empty>
        {structure &&
          [...structure.files]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((file) => <CommandItem key={file.id} file={file} onSelect={() => setOpen(false)} />)}
      </Command.List>
    </Command.Dialog>
  );
};

const CommandItem = ({ file, onSelect }: { file: DirectoryFile & { path: string }; onSelect: () => void }) => {
  const href = useAppHref({ type: "file", id: file.id });
  const router = useRouter();
  return (
    <Command.Item
      key={file.id}
      value={file.path}
      keywords={[file.id, file.directory_id]}
      className="group/searchitem"
      onSelect={() => {
        router.push(href);
        onSelect();
      }}
    >
      <Link href={href}>
        <SearchItem entityType={file.type} path={file.path} title={file.name} />
      </Link>
    </Command.Item>
  );
};
