import { ChatMessage } from "@/services/playground.service";
import { BaseFileResponse, FileFromResponse } from "./file";
import { parseTimestampsInResponse } from "@/services/utils";
import { parseEnvironmentResponse } from "./environment";
import { ApiService } from "@/services/api.service";
import { GetFileProps, getFileUrl } from "@/services/files.service";
import { AxiosResponse } from "axios";
import { FileRequestIdentifiers } from "./log";

export type DatapointTarget = Record<string, any> | null;

export interface CreateDatapointRequest {
  inputs: Record<string, string>;
  messages: ChatMessage[] | null;
  target: DatapointTarget;
}

export interface Datapoint extends CreateDatapointRequest {
  id: string;
}

type UpdateDatasetAction = "set" | "add" | "remove";

interface CreateDatasetRequest {
  datapoints: CreateDatapointRequest[];
  action?: UpdateDatasetAction;
  attributes?: Record<string, any> | null;
}

export interface DatasetRequest extends CreateDatasetRequest, FileRequestIdentifiers {
  commit_message?: string | null;
}

export interface UpdateDatasetRequest {
  path?: string | null;
  name?: string | null;
}

export interface DatasetResponse
  extends Omit<DatasetRequest, "id" | "path" | "directory_id" | "datapoints">,
    BaseFileResponse<"dataset"> {
  datapoints_count: number;
  datapoints: Datapoint[] | null;
  team_id: string;
}

export type Dataset = FileFromResponse<DatasetResponse>;

export const parseDatasetResponse = (response: DatasetResponse): Dataset => {
  return {
    ...parseTimestampsInResponse(response, ["created_at", "updated_at", "committed_at", "last_used_at"]),
    environments: response.environments.map(parseEnvironmentResponse),
  };
};

export const getDatasetWithDatapoints = async (props: GetFileProps): Promise<Dataset & { datapoints: Datapoint[] }> => {
  const response: AxiosResponse<DatasetResponse & { datapoints: Datapoint[] }> = await ApiService.get(
    getFileUrl(props, { include_datapoints: "true" }),
  );
  return parseDatasetResponse(response.data) as Dataset & { datapoints: Datapoint[] };
};
