import { getAuthToken } from "@/lib/use-auth";
import useSWR, { Arguments, SWRConfiguration, mutate } from "swr";
import { ApiService } from "./api.service";
import { all, AxiosPromise, AxiosResponse } from "axios";
import { capitalizedFileType, FilesSortBy, updateFile } from "./files.service";
import { FileType, File, parseFileResponse, FileResponse } from "@/types/app/file";
import { ReplaceKeysInType, parseTimestampsInResponse } from "./utils";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import produce from "immer";
import { WritableDraft } from "immer/dist/internal";
import { ToastVariant, hlToast, hlToastApiError } from "@components/library/Toast";
import { EvaluatorType } from "@/types/app/evaluator";
import { ROOT_DIRECTORY_NAME } from "@/lib/constants";
import { confirmMovePathWarning } from "@components/llama/Projects/MoveDirectoryModal";
import useConfirm, { ConfirmType } from "@/context/useConfirm";
import { pluralise } from "@/lib/utils";
import Button from "@components/library/Button";
import { toast } from "react-hot-toast";
import { ButtonWithShortcut } from "@components/atoms/ButtonWithShortcut";

export interface DirectoryResponse {
  id: string;
  name: string;
  readme: string | null;
  description: string;
  tags: string[];
  parent_id: string; 
  created_at: string;
  updated_at: string;
}

export type Directory = ReplaceKeysInType<DirectoryResponse, "created_at" | "updated_at", dayjs.Dayjs> & {
  type: "directory";
};

export const useRootDirectory = (swrOptions: SWRConfiguration<Directory> = {}) => {
  const { data, error, mutate } = useSWR<Directory>(
    ["/v5/directories/root", getAuthToken()],
    directoryFetcher,
    swrOptions,
  );
  return {
    directory: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

const directoryFetcher = async (...args: Parameters<typeof ApiService.getWithToken>): Promise<Directory> => {
  const response: DirectoryResponse = await ApiService.getWithToken(...args);
  return parseDirectoryResponse(response);
};

const parseDirectoryResponse = (response: DirectoryResponse): Directory => {
  return { ...parseTimestampsInResponse(response, ["created_at", "updated_at"]), type: "directory" };
};

export const useDirectories = (swrOptions: SWRConfiguration<Directory[]> = {}) => {
  const { data, error, mutate } = useSWR<Directory[]>(
    ["/v5/directories", getAuthToken()],
    directoriesFetcher,
    swrOptions,
  );
  return {
    directories: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

const directoriesFetcher = async (...args: Parameters<typeof ApiService.getWithToken>): Promise<Directory[]> => {
  const response: DirectoryResponse[] = await ApiService.getWithToken(...args);
  return response.map(parseDirectoryResponse);
};

interface CreateDirectoryRequest {
  name: string;
  parent_id: string;
}

export const createDirectory = async (request: CreateDirectoryRequest): AxiosPromise<Directory> => {
  const response: AxiosResponse<DirectoryResponse> = await ApiService.post(`/v5/directories`, request);
  return { ...response, data: parseDirectoryResponse(response.data) };
};

export const cloneDirectory = async (id: string, targetDirectoryId?: string): AxiosPromise<Directory> => {
  const response: AxiosResponse<DirectoryResponse> = await ApiService.post(
    `/v5/directories/${id}/clone`,
    targetDirectoryId ? { parent_id: targetDirectoryId } : {},
  );
  return { ...response, data: parseDirectoryResponse(response.data) };
};

interface UpdateDirectoryRequest {
  name?: string;
  parent_id?: string;
  tags?: string[];
  description?: string;
  readme?: string;
}

export const updateDirectory = async (id: string, request: UpdateDirectoryRequest): AxiosPromise<Directory> => {
  const response: AxiosResponse<DirectoryResponse> = await ApiService.patch(`/v5/directories/${id}`, request);
  return { ...response, data: parseDirectoryResponse(response.data) };
};

export const deleteDirectory = (id: string): AxiosPromise<void> => {
  return ApiService.remove(`/v5/directories/${id}`);
};

export interface DirectoryWithParentsAndChildren extends Directory {
  subdirectories: Directory[];
  files: File[];
  parents: Directory[];
}

export interface DirectoryWithParentsAndChildrenResponse extends DirectoryResponse {
  subdirectories: DirectoryResponse[];
  files: FileResponse[];
  parents: DirectoryResponse[];
}

/** Fetch a single directory */
export const useDirectory = (id: string | null, swrOptions: SWRConfiguration<DirectoryWithParentsAndChildren> = {}) => {
  const { data, error, mutate, ...others } = useSWR<DirectoryWithParentsAndChildren>(
    id !== null ? [`/v5/directories/${id}`, getAuthToken()] : null,
    directoryWithParentsAndChildrenFetcher,
    swrOptions,
  );
  return {
    ...others,
    directory: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

/**
 * Global mutate to update the specified directories.
 *
 * See: https://swr.vercel.app/docs/mutation#mutate-multiple-items
 */
export const refetchDirectory = (directoryIds: string[]): void => {
  mutate(
    (key: Arguments) => {
      if (Array.isArray(key)) {
        const url = key[0];
        if (url === "/v5/directories") {
          return true;
        }
        if (url.startsWith("/v5/directories/")) {
          const directoryId = url.slice("/v5/directories/".length);
          return directoryIds.includes(directoryId);
        }
        return false;
      }
      return false;
    },
    undefined,
    { revalidate: true },
  );
};

export const getDirectoryIdFromItem = (item: FileOrDirectory): string => {
  switch (item.type) {
    case "directory":
      return item.item.id;
    case "file":
      return item.item.directory_id;
  }
};

const directoryWithParentsAndChildrenFetcher = async (
  ...args: Parameters<typeof ApiService.getWithToken>
): Promise<DirectoryWithParentsAndChildren> => {
  const response: DirectoryWithParentsAndChildrenResponse = await ApiService.getWithToken(...args);
  return parseDirectoryWithParentsAndChildrenResponse(response);
};

const parseDirectoryWithParentsAndChildrenResponse = (
  response: DirectoryWithParentsAndChildrenResponse,
): DirectoryWithParentsAndChildren => {
  return {
    ...parseDirectoryResponse(response),
    subdirectories: response.subdirectories.map(parseDirectoryResponse),
    files: response.files.map(parseFileResponse),
    parents: response.parents.map(parseDirectoryResponse),
  };
};

interface MoveOperation {
  forward: () => Promise<any>;
  reverse: () => Promise<any>;
  structureUpdate: DirectoryStructureChange;
  reverseStructureUpdate: DirectoryStructureChange;
}

/**
 * Move files and directories to a new directory.
 *
 * Returns a list of operations that can be used to perform the move.
 *
 * The operations are returned in reverse order, so that the last operation
 * can be used to reverse the move.
 */
export const moveItemOperations = (items: FileOrDirectory[], targetDirectoryId: string): MoveOperation[] => {
  return items
    .map((item): MoveOperation | null => {
      switch (item.type) {
        case "directory": {
          // Discard any directory moves into the same directory they're already in
          if (item.item.parent_id === targetDirectoryId) return null;
          const originalParentId = item.item.parent_id ?? null;
          return {
            forward: () => updateDirectory(item.item.id, { parent_id: targetDirectoryId }),
            reverse: () => updateDirectory(item.item.id, { parent_id: originalParentId }),
            structureUpdate: {
              type: "move",
              item,
              targetDirectoryId,
            },
            reverseStructureUpdate: {
              type: "move",
              item,
              targetDirectoryId: originalParentId,
            },
          };
        }
        case "file": {
          if (item.item.directory_id === targetDirectoryId) return null;
          const originalDirectoryId = item.item.directory_id;
          return {
            forward: () => updateFile(item.item.id, { directory_id: targetDirectoryId }),
            reverse: () => updateFile(item.item.id, { directory_id: originalDirectoryId }),
            structureUpdate: {
              type: "move",
              item,
              targetDirectoryId,
            },
            reverseStructureUpdate: {
              type: "move",
              item,
              targetDirectoryId: originalDirectoryId,
            },
          };
        }
      }
    })
    .filter((op): op is MoveOperation => op !== null);
};

export const moveItemsAndHandleStructureUpdates = async ({
  items,
  targetDirectoryId,
  targetDirectoryName,
  onChange,
  confirm,
  onSuccess,
  onError,
}: {
  items: FileOrDirectory[];
  targetDirectoryId: string;
  targetDirectoryName: string;
  onChange: (changes?: DirectoryStructureChange[]) => void;
  confirm: ConfirmType;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) => {
  const moveOperations = moveItemOperations(items, targetDirectoryId);
  console.log({ moveOperations });

  if (moveOperations.length === 0) {
    console.log("No moves to perform");
    return;
  }

  // Check if we need to warn the user about changing a path
  if (!(await confirmMovePathWarning(items, targetDirectoryName, confirm))) {
    hlToast({
      title: "Move cancelled",
      variant: ToastVariant.Error,
    });
    return;
  }

  const structureUpdates = moveOperations.map((op) => op.structureUpdate);

  try {
    // Kick off all the moves
    const movePromises = moveOperations.map((op) => op.forward());

    // Optimistically update the entire directory structure
    onChange(structureUpdates);

    // Use Promise.allSettled to handle all promises, rather than Promise.all,
    // because Promise.all will throw an error if any of the promises fail.
    const results = await Promise.allSettled(movePromises);

    // Track successful and failed operations
    const successfulOps: MoveOperation[] = [];
    const failedOps: { op: MoveOperation; error: any }[] = [];

    results.forEach((result, index) => {
      if (result.status === "fulfilled") {
        successfulOps.push(moveOperations[index]);
      } else {
        failedOps.push({ op: moveOperations[index], error: result.reason });
      }
    });

    // Handle any failures
    if (failedOps.length > 0) {
      console.error("Errors occurred while moving items:", failedOps);

      // Revert structure updates only for failed operations
      const reverseUpdates = failedOps.map(({ op }) => op.reverseStructureUpdate);
      onChange(reverseUpdates);

      // Handle specific errors (e.g., 409 conflicts)
      const conflictError = failedOps.find(({ error }) => error?.response?.status === 409);
      if (conflictError) {
        const conflictingItem = items[moveOperations.indexOf(conflictError.op)];
        const itemType =
          conflictingItem.type === "directory" ? "directory" : capitalizedFileType(conflictingItem.item.type);

        hlToast({
          title: "File name conflict",
          description:
            items.length === 1
              ? `A file with name '${conflictingItem.item.name}' already exists in the target directory`
              : `One or more files already exist in the target directory`,
          variant: ToastVariant.Error,
        });
      }
    }

    // Show success toast for successful operations
    if (successfulOps.length > 0) {
      const description = (
        <div className="flex w-full items-center gap-16">
          <span>
            Moved {successfulOps.length > 1 ? `${successfulOps.length} items` : <strong>{items[0].item.name}</strong>}{" "}
            into{" "}
            {targetDirectoryName === ROOT_DIRECTORY_NAME ? (
              "the root directory"
            ) : (
              <strong>{targetDirectoryName}</strong>
            )}
          </span>
          <ButtonWithShortcut
            size={24}
            hideShortcut
            shortcut="mod+z"
            onClick={async () => {
              try {
                // Execute all reverse operations for successful moves
                await Promise.allSettled(successfulOps.map((op) => op.reverse()));
                onChange(successfulOps.map((op) => op.reverseStructureUpdate));
                toast.dismiss();
                hlToast({
                  title: "Move operation undone",
                  variant: ToastVariant.Success,
                });
              } catch (e) {
                console.error("Error undoing move:", e);
                hlToastApiError({ error: e, titleFallback: "Failed to undo move" });
              }
            }}
          >
            Undo
          </ButtonWithShortcut>
        </div>
      );

      hlToast({
        title: successfulOps.length > 1 ? `Moved ${successfulOps.length} items` : `Moved '${items[0].item.name}'`,
        variant: ToastVariant.Success,
        description,
      });
    }

    // Call appropriate callbacks
    if (failedOps.length > 0) {
      onError?.(failedOps[0].error);
    } else {
      onSuccess?.();
    }
  } catch (e: any) {
    console.error("Error moving items:", e);

    // Revert the optimistic update
    onChange();

    hlToastApiError({ error: e, titleFallback: "Failed to move items" });
    onError?.(e);
  }
};

interface DirectoryFileResponse {
  id: string;
  name: string;
  description: string | null;
  readme: string | null;
  tags: string[];
  type: FileType;
  // TODO: add path to this response. Save us doing gymnastics here to recreate something so necessary.
  // https://linear.app/humanloop/issue/ENG-1068/consider-making-path-saved-against-files-and-directories-in-db
  directory_id: string;
  num_logs: number;
  evaluator_type: EvaluatorType | null;
  created_at: string;
  updated_at: string;
}

export type DirectoryFile = ReplaceKeysInType<DirectoryFileResponse, "created_at" | "updated_at", dayjs.Dayjs>;

export const isDirectoryFile = (file: DirectoryFile | Directory): file is DirectoryFile => {
  // This is correct. The Directories have 'id' the files have 'id' and 'directory_id'
  return "directory_id" in file;
};

/**
 * Helper function to convert a File to a DirectoryFile.
 * These are very similar types, with the DirectoryFile type having the required (but nullable)
 * `evaluator_type` field which makes some function type-checking easier.
 */
export const directoryFileFromFile = (file: File): DirectoryFile => ({
  ...file,
  description: "description" in file && typeof file.description === "string" ? file.description : null,
  num_logs: "total_logs_count" in file ? file.total_logs_count : file.datapoints_count,
  evaluator_type: file.type === "evaluator" ? file.spec.evaluator_type : null,
});

interface DirectoryStructureResponse {
  directories: DirectoryResponse[];
  files: DirectoryFileResponse[];
}

export interface DirectoryStructure {
  directories: Directory[];
  files: DirectoryFile[];
}
interface DirectoryStructureWithPaths {
  directories: (Directory & { path: string })[];
  files: (DirectoryFile & { path: string })[];
}

export type FileOrDirectory = { type: "directory"; item: Directory } | { type: "file"; item: DirectoryFile };

export type FileOrDirectoryWithParentsAndChildren =
  | { type: "directory"; item: DirectoryWithParentsAndChildren }
  | { type: "file"; item: DirectoryFile };

export type DirectoryStructureChange =
  | {
      type: "move";
      item: FileOrDirectory;
      targetDirectoryId: string;
    }
  | {
      type: "delete" | "update" | "create";
      item: FileOrDirectory;
    };

// TODO: remove this, and do all this on the backend, only not done now for
// https://linear.app/humanloop/issue/ENG-1068/consider-making-path-saved-against-files-and-directories-in-db
export function addPathsToDirectoryStructure(directoryStructure: DirectoryStructure): DirectoryStructureWithPaths {
  const directoryMap = new Map<string, Directory>();

  function getPath(dir: Directory & { path?: string }): string {
    if (dir.path) return dir.path;
    if (dir.name === ROOT_DIRECTORY_NAME) return "";
    if (!dir.parent_id) return dir.name;

    const parent = directoryMap.get(dir.parent_id);
    if (!parent) return dir.name;

    const parentPath = getPath(parent);
    return parentPath ? `${parentPath}/${dir.name}` : dir.name;
  }

  // Process directories
  const updatedDirectories = directoryStructure.directories.map((dir) => {
    directoryMap.set(dir.id, dir);
    return { ...dir, path: getPath(dir) };
  });

  // Process files
  const updatedFiles = directoryStructure.files.map((file) => {
    const dir = directoryMap.get(file.directory_id);
    const dirPath = dir ? getPath(dir) : "";
    return { ...file, path: dirPath ? `${dirPath}/${file.name}` : file.name };
  });

  return { directories: updatedDirectories, files: updatedFiles };
}

/** Directory structure without much of the content */
export const useDirectoryStructure = (
  { template }: { template: boolean } = { template: false },
  swrOptions: SWRConfiguration<DirectoryStructure> = {},
) => {
  const { data, error, mutate, ...others } = useSWR<DirectoryStructure>(
    [`/v5/directories/structure${template ? "?template=true" : ""}`, getAuthToken()],
    directoryStructureFetcher,
    swrOptions,
  );

  /** Handles changes to the directory structure with optimistic updates. */
  const onChange = useCallback(
    (changes?: DirectoryStructureChange[]) => {
      // <rant>
      // SWR has 2 ways to provide optimistic updates:
      // 1. mutate((data) => data | undefined)
      // 2. mutate(undefined, {optimisticData: (currentData, displayedData) => data})
      // I don't know why the second option does not let you return undefined
      // when both currentData and displayedData can be undefined)
      // Ideally we use the second calling style's `displayedData` (so that a
      // create-followed-by-rename-of-created-item does not fail/cause the created
      // item to disappear).
      // </rant>
      // TODO: Consider using immer to make below code less verbose.
      mutate(
        changes
          ? produce((draft: WritableDraft<typeof data>) => {
              if (draft === undefined) {
                return draft;
              }

              changes.forEach((change) => {
                switch (change.item.type) {
                  case "file":
                    const changeItemFile = change.item.item;
                    switch (change.type) {
                      case "move":
                        const changedFile = draft.files.find((file) => file.id === changeItemFile.id);
                        if (changedFile === undefined) {
                          console.error(`File with id ${changeItemFile.id} not found in directory structure`, change);
                          return;
                        }
                        changedFile.directory_id = change.targetDirectoryId;
                        break;
                      case "delete":
                        draft.files = draft.files.filter((file) => file.id !== changeItemFile.id);
                        break;
                      case "update":
                        draft.files = draft.files.map((file) =>
                          file.id === changeItemFile.id ? changeItemFile : file,
                        );
                        break;
                      case "create":
                        draft.files = [...draft.files, changeItemFile];
                        break;
                      default:
                        // @ts-expect-error Should handle all change types above.
                        throw new Error(`Unhandled change type '${change.type}'`);
                    }
                    break;
                  case "directory":
                    const changeItemDirectory = change.item.item;
                    switch (change.type) {
                      case "move":
                        const changedDirectory = draft.directories.find(
                          (directory) => directory.id === changeItemDirectory.id,
                        );
                        if (changedDirectory === undefined) {
                          console.error(
                            `Directory with id ${changeItemDirectory.id} not found in directory structure`,
                            change,
                          );
                          return;
                        }
                        changedDirectory.parent_id = change.targetDirectoryId;
                        break;
                      case "delete":
                        draft.directories = draft.directories.filter(
                          (directory) => directory.id !== changeItemDirectory.id,
                        );
                        break;
                      case "update":
                        draft.directories = draft.directories.map((directory) => {
                          return directory.id === changeItemDirectory.id
                            ? (changeItemDirectory as Directory)
                            : directory;
                        });
                        break;
                      case "create":
                        draft.directories = [...draft.directories, changeItemDirectory];
                        break;
                      default:
                        // @ts-expect-error Should handle all change types above.
                        throw new Error(`Unhandled change type '${change.type}'`);
                    }
                    break;

                  default:
                    // @ts-expect-error Should handle all change item types above.
                    throw new Error(`Unhandled change item type '${change.item.type}'`);
                }
              });

              return draft;
            })
          : undefined,
        changes ? false : true, // Revalidation assumed unnecessary if we're providing the changes.
      );
      if (changes) {
        // Also mutate the directory contents to ensure the directory structure is up-to-date.
        const changedDirectoryIds = changes.map((change) => {
          switch (change.item.type) {
            case "file":
              return change.item.item.directory_id;
            case "directory":
              return change.item.item.id;
          }
        });
        // We also need to refetch the parent directories of the changed directories.
        const changedDirectoryParentIds = changes
          .map((change) => {
            if (change.item.type === "directory") {
              return change.item.item.parent_id;
            }
          })
          .filter((id) => id !== undefined && id !== null) as string[];
        refetchDirectory(changedDirectoryIds.concat(changedDirectoryParentIds));
      }
    },
    [mutate],
  );

  return {
    ...others,
    structure: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    onChange,
  };
};
export const mutateDirectoryStructure = () => {
  mutate([`/v5/directories/structure`, getAuthToken()]);
};

export const renameDirectory = async ({
  directory,
  name,
  onSuccess,
  onError,
}: {
  directory: Directory;
  name: string;
  onSuccess?: (directory: Directory) => void;
  onError?: (error: any) => void;
}) => {
  try {
    const newDirectory = (await updateDirectory(directory.id, { name: name })).data;
    onSuccess?.(newDirectory);
    hlToast({
      title: "Directory renamed",
      description: `Directory '${directory.name}' has been renamed to '${directory.name}'`,
      variant: ToastVariant.Success,
    });
  } catch (error) {
    console.log(error);
    onError?.(error);
    hlToastApiError({ error, titleFallback: "Failed to rename directory" });
  }
};

const directoryStructureFetcher = async (
  ...args: Parameters<typeof ApiService.getWithToken>
): Promise<DirectoryStructure> => {
  const response: DirectoryStructureResponse = await ApiService.getWithToken(...args);
  return parseDirectoryStructureResponse(response);
};

export const parseDirectoryStructureResponse = (response: DirectoryStructureResponse): DirectoryStructure => {
  return {
    directories: response.directories.map((directory) => ({
      ...parseTimestampsInResponse(directory, ["created_at", "updated_at"]),
      type: "directory",
    })),
    files: response.files.map((file) => parseTimestampsInResponse(file, ["created_at", "updated_at"])),
  };
};
