import { getAuthToken } from "@/lib/use-auth";
import { AxiosPromise } from "axios";
import useSWR, { SWRConfiguration } from "swr";
import { ApiService } from "./api.service";
import { GraphData, GraphRequestParams } from "./metrics.service";
import { ChatMessage } from "./playground.service";
import { EvaluatorAggregate } from "@/types/app/version";
import { MonitoringEvaluator } from "./files.service";
import { EvaluatorSpec } from "@/types/app/evaluator";
import { EvaluatorLogResponse, LogResponse } from "@/types/app/log";

export type EvaluationJudgmentValue = boolean | number | string | string[] | null;

export interface EvaluationResultBase {
  log_id: string;
  value: EvaluationJudgmentValue;
  error: string | null;
  stdout: string | null;
}

export const queueEvaluatorRun = (fileId: string, logIds: string[]): AxiosPromise<void> => {
  return ApiService.post(`/v5/evaluators/run`, { file_id: fileId, log_ids: logIds });
};

export const queueEvaluatorRunWholeFile = (fileId: string): AxiosPromise<void> => {
  return ApiService.post(`/v5/evaluators/run`, { file_id: fileId });
};

/** Model for the Log dict exposed to Evaluators */
export interface DebugEvaluationLog {
  id: string;
  model_config: unknown;
  // Contains other fields that are not necessary to specify here as this is displayed as JSON.
}

// Get random logs for use in Evaluators debug console.
export const getRandomDebugLogs = (fileId: string, count: number): AxiosPromise<DebugEvaluationLog[]> => {
  return ApiService.get(`/v5/evaluators/debug/${fileId}/random-data/${count}`);
};
// Get logs for use in Evaluators debug console. (E.g. if opening log in editor)
export const getDebugConsoleDataForLogs = (
  fileId: string,
  logIds: string[],
): AxiosPromise<{ debug_log: DebugEvaluationLog; result: EvaluationDebugResult }[]> => {
  const params = new URLSearchParams();
  logIds.forEach((id) => params.append("log_ids", id));
  return ApiService.get(`/v5/evaluators/debug/${fileId}/logs?${params.toString()}`);
};

export interface EvaluationDebugResult extends EvaluationResultBase {
  datapoint_id: string | null;
  log: LogResponse;
  evaluation_log: EvaluatorLogResponse | null;
}

interface DebugEvaluatorRequest {
  file_id: string;
  evaluator: EvaluatorSpec;
  evaluator_version_id?: string | null; // The ID of the Evaluator Version being debugged if it already exists and is being edited.

  // One of `log_ids` or `datapoint_ids` must be provided.
  log_ids?: string[];
  datapoint_ids?: string[];
  prompt_version_id?: string; // Only required if `datapoint_ids` are provided.
}

export const runSyncEvaluator = (
  fileId: string,
  spec: EvaluatorSpec,
  ids:
    | {
        logIds: string[];
      }
    | {
        promptVersionId: string;
        datapointIds: string[];
      },
  updatingEvaluatorId: string | null = null,
): AxiosPromise<EvaluationDebugResult[]> => {
  const request: DebugEvaluatorRequest =
    "logIds" in ids
      ? {
          file_id: fileId,
          evaluator: spec,
          evaluator_version_id: updatingEvaluatorId,
          log_ids: ids.logIds,
        }
      : {
          file_id: fileId,
          evaluator: spec,
          evaluator_version_id: updatingEvaluatorId,
          prompt_version_id: ids.promptVersionId,
          datapoint_ids: ids.datapointIds,
        };
  return ApiService.post(`/v5/evaluators/debug`, request);
};

export type EvaluationStatus = "pending" | "running" | "completed" | "cancelled"; // TODO: Remove "failed" when removing evaluation runs.

export const COST_EVALUATOR_NAME = "Cost";
export const TOKENS_EVALUATOR_NAME = "Tokens";
export const LATENCY_EVALUATOR_NAME = "Latency";

export const DEFAULT_EVALUATOR_NAMES = [COST_EVALUATOR_NAME, TOKENS_EVALUATOR_NAME, LATENCY_EVALUATOR_NAME];

// TODO: this is a quick hack that should be cleared up with v5 in the API.
//  This is a evaluator (that is enabled for that file) with the aggregates.
export type EvaluatorWithAggregates = {
  aggregate: EvaluatorAggregate;
  monitoringEvaluator: MonitoringEvaluator;
};

/** Get the evaluation aggregates for the monitoring evaluators for a file version.
 *
 * This is different to an evaluation result - it's used to show the perf of a prompt version etc on the dashboard.
 *
 *  TODO: This is hack on top of some ugly separation of monitoring evaluators, results and hard-coded cost/latency
 *  evaluators. Please clean this up with v5.
 *  This is primarily so I can get a name for each evaluator (which should just be in API.
 *  I really hope this code doesn't exist in 2 months.
 * */
export const getEvaluatorWithAggregates = (
  evaluationAggregates?: EvaluatorAggregate[],
  monitoringEvaluators?: MonitoringEvaluator[],
) => {
  if (!evaluationAggregates) {
    return { defaultEvals: [], customEvals: [] };
  }
  const evaluationResults =
    evaluationAggregates.reduce((acc, evalAgg) => {
      const monitoringEvaluator = monitoringEvaluators?.find(
        (monitoringEvaluator) => monitoringEvaluator.version?.version_id === evalAgg.evaluator_version_id,
      );
      return monitoringEvaluator ? [...acc, { aggregate: evalAgg, monitoringEvaluator }] : acc;
    }, [] as EvaluatorWithAggregates[]) || [];

  const defaultEvals: EvaluatorWithAggregates[] = [];
  const customEvals: EvaluatorWithAggregates[] = [];
  evaluationResults.forEach((evaluator) => {
    const evaluatorName = evaluator.monitoringEvaluator.version?.name;
    if (evaluatorName && DEFAULT_EVALUATOR_NAMES.includes(evaluatorName)) {
      defaultEvals.push(evaluator);
    } else {
      customEvals.push(evaluator);
    }
  });
  return { defaultEvals, customEvals };
};

export const useEvaluationResultsGraphData = (
  projectId?: string,
  evaluatorId?: string,
  request?: GraphRequestParams,
  swrOptions: SWRConfiguration<Record<string, GraphData[]>> = {},
) => {
  const params = new URLSearchParams();
  if (request) {
    params.append("start", String(request.start));
    params.append("end", String(request.end));
    params.append("time_unit", String(request.timeUnit));
  }
  const { data, error, mutate } = useSWR<Record<string, GraphData[]>>(
    projectId && evaluatorId && request
      ? [`/projects/${projectId}/graphs/evaluators?${params.toString()}`, getAuthToken()]
      : null,
    swrOptions,
  );

  const evaluationResultsGraphData = data && evaluatorId ? data[evaluatorId] : null;

  return { evaluationResultsGraphData, error: error, loading: !data && !error, mutate };
};

export interface RunEvaluatorsRequest {
  logId: string;
  evaluatorVersionIds: string[];
}

export const runEvaluatorsOnLog = ({
  logId,
  evaluatorVersionIds,
}: RunEvaluatorsRequest): AxiosPromise<LogResponse[]> => {
  return ApiService.post(`/v5/evaluations/run-evaluators`, {
    log_id: logId,
    evaluator_version_ids: evaluatorVersionIds,
  });
};
