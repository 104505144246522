import { ConditionalWrapper } from "@/lib/utils";
import Tooltip from "@components/library/Tooltip";
import { CommandIcon } from "@library/Icons";
import { PLACEHOLDER_HOTKEY } from "lib/constants";
import { isArray, isString } from "lodash";
import { useRouter } from "next/router";
import React from "react";
import { isMacOs } from "react-device-detect";
import { useHotkeys } from "react-hotkeys-hook";
import Button, { ButtonProps } from "../library/Button";

interface WithShortcutProps {
  shortcut: string | string[] | null; // See https://react-hotkeys-hook.vercel.app/ for syntax of key combinations.
  tooltip?: React.ReactNode;
  hideShortcut?: boolean;
  noRepeat?: boolean;
}

// This is a wrapper around a standard Button which adds a the keyboard shortcut interaction.
// This isn't included within the Button component itself, as the various ways keypresses
// are added feels a bit hacky, and I'm wondering whether adding this extra hook
// to every button could be a performance hit (untested, probably untrue).
export const ButtonWithShortcut = React.forwardRef(function ButtonWithShortcutComponent(
  { hideShortcut, tooltip, ...props }: ButtonProps & WithShortcutProps,
  ref: React.ForwardedRef<HTMLButtonElement> | React.ForwardedRef<HTMLAnchorElement>,
) {
  const [buttonActive, setButtonActive] = React.useState(false);
  const enabled = (isString(props.shortcut) || isArray(props.shortcut)) && !props.disabled;
  const router = useRouter();

  const shortcut = props.shortcut ?? PLACEHOLDER_HOTKEY;
  const keys: React.ReactNode[] =
    (isArray(props.shortcut) ? props.shortcut[0] : props.shortcut)?.split("+").map((key) => {
      if (["cmd", "command", "mod"].includes(key)) {
        if (isMacOs) return <CommandIcon key={key} className="h-12 w-12" />;
        return "Ctrl";
      }
      return key;
    }) || [];

  const isKeyCombo = keys.length > 1;

  useHotkeys(
    shortcut,
    (event) => {
      if (event.type === "keydown") {
        setButtonActive(true);
        if (isKeyCombo) {
          setTimeout(() => {
            setButtonActive(false);
          }, 100);
        }
      } else if (event.type === "keyup") {
        setButtonActive(false);
        return;
      }
      if (props.noRepeat && event.repeat) {
        return;
      }

      // TODO: bit of hack using a mouse handler as a keyboard handler, potentially we should generalise to onSelect
      props.onClick && props.onClick(event as unknown as React.MouseEvent<HTMLButtonElement>);
      props.href && router.push(props.href);
    },
    { enabled: enabled, keydown: true, keyup: true },
    [shortcut, enabled, props.noRepeat, props.onClick],
  );

  return (
    <ConditionalWrapper
      condition={!!(tooltip && enabled)}
      wrapper={(children) => <Tooltip content={tooltip}>{children}</Tooltip>}
    >
      <Button
        ref={ref as any}
        keyboard={props.shortcut && !hideShortcut ? keys : undefined}
        {...props}
        active={buttonActive || props.active}
      >
        {props.children}
      </Button>
    </ConditionalWrapper>
  );
});
